import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import React, { useEffect } from 'react';
import { auth } from '../firebase.config';
import axios from '../services/api';
import { message } from 'antd';

export const logout = async () => {
    await signOut(auth);
};

// create auth context
export const AuthContext = React.createContext({
    user: null,
    signIn: () => {},
    signOut: () => {},
});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const signIn = async (username, password) => {
        setLoading(true);
        axios
            .post('/admin/login', {
                username: username,
                password: password,
            })
            .then((response) => {
                console.log(response);
                setUser(response.data);
                localStorage.setItem('user', JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
                message.error(error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const signOut = async () => {
        localStorage.removeItem('user');
        setUser(null);
    };

    useEffect(() => {
        const user = localStorage.getItem('user');
        if (user) {
            setUser(JSON.parse(user));
        }
        setLoading(false);
    }, []);
    // React.useEffect(() => {
    //     const unsubscribe = auth.onAuthStateChanged((user) => {
    //         console.log(user);

    //         setUser(user);
    //         auth.currentUser
    //             ?.getIdToken(true)
    //             .then((idToken) => {
    //                 console.log(idToken);
    //             })
    //             .catch((error) => {
    //                 // Handle error
    //             });
    //         setLoading(false);
    //     });
    //     // get token
    //     return unsubscribe;
    // }, []);

    const value = { user, signIn, signOut, loading };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    return { ...context };
};
