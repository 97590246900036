import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import axios from '../../services/api';

import DropdownButton from '../../components/DropdownButton';
import { Button, Checkbox, DatePicker, Dropdown, Image, message, Modal, Popover, Switch } from 'antd';

import { useAuth } from '../../context/AuthContext';
// const BookingCard = ({ booking }) => {
//     return (
//         <div className="flex flex-col w-full max-w-full mb-6 md:w-1/3 lg:w-1/3 my-2 xl:w-1/4 md:mb-0">
//             <div className="mx-2  py-3 px-5  rounded-lg bg-slate-300 dark:bg-slate-900 shadow-lg">
//                 <div className="flex flex-row items-center">
//                     <div>
//                         <span>#900</span>
//                         {
//                             // active with green border
//                             <span className="px-2 py-1 ml-2 text-xs font-semibold text-green-900 uppercase bg-green-200 rounded-full">Active</span>
//                         }
//                     </div>
//                     <i className="fas fa-ellipsis-v ml-auto"></i>
//                 </div>
//                 <div className="flex flex-row items-center my-3">
//                     <img src="https://www.w3schools.com/howto/img_avatar.png" alt="vehicle" className="w-20 h-20 rounded-full" />
//                     <div className="flex flex-col ml-4">
//                         <span className="text-sm font-medium text-gray-700 dark:text-white">John Doe</span>
//                         <span className="text-sm font-medium text-gray-500 dark:text-white">+91 9999999999</span>
//                         <span className="text-sm font-medium text-gray-700 dark:text-white">Maruti Suzuki Swift</span>
//                         <span className="text-sm font-medium text-gray-500 dark:text-white">DL 1C 1234</span>
//                     </div>
//                 </div>
//                 <hr className="w-full my-3 border-t-2 border-dashed border-gray-300 dark:border-gray-700" />
//                 <div className="flex flex-row py-1 my-3">
//                     <div className="flex flex-col">
//                         <span className="text-sm font-semibold text-gray-800 dark:text-white">START</span>
//                         <span className="text-sm font-medium text-gray-700 dark:text-white">12/12/2021 10:00 AM</span>
//                     </div>
//                     <div className="flex flex-col ml-auto text-right">
//                         <span className="text-sm font-semibold text-gray-500 dark:text-white">END</span>
//                         <span className="text-sm font-medium text-gray-700 dark:text-white">12/12/2021 10:00 AM</span>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

const AddtionalDetailsButton = ({ booking }) => {
    const [modal, setModal] = React.useState(false);

    return (
        <>
            <button
                onClick={() => {
                    setModal(true);
                }}
                className="flex flex-row items-center px-2 py-1 text-sm font-medium text-gray-700 bg-gray-200 rounded-md dark:bg-gray-700 dark:text-gray-200"
            >
                <i className="fas fa-info-circle mr-2"></i>
                <span>Additional Details</span>
            </button>
            <Modal
                title="Additional Details"
                className=""
                open={modal}
                onCancel={() => {
                    setModal(false);
                }}
                footer={null}
            >
                <div className="flex flex-col">
                    <div className="flex flex-row items-center justify-between">
                        <span className="text-sm font-medium text-gray-700">Start Kilometers</span>
                        <span className="text-sm font-medium text-gray-500 ml-auto">{booking.startKm}</span>
                    </div>
                    <div className="flex flex-row items-center justify-between my-3">
                        <span className="text-sm font-medium text-gray-700 ">Start Fuel</span>
                        <span className="text-sm font-medium text-gray-500 ml-auto">{booking.startFuel}</span>
                    </div>
                    <div className="flex flex-row items-center justify-between my-3">
                        <span className="text-sm font-medium text-gray-700 ">End Kilometers</span>
                        <span className="text-sm font-medium text-gray-500  ml-auto">{booking.endKm}</span>
                    </div>
                    <div className="flex flex-row items-center justify-between my-3">
                        <span className="text-sm font-medium text-gray-700 ">End Fuel</span>
                        <span className="text-sm font-medium text-gray-500  ml-auto">{booking.endFuel}</span>
                    </div>
                    {booking.startImage && (
                        <div className="flex flex-row justify-between items-center my-3">
                            <span className="text-sm font-medium text-gray-700 dark:text-white">Start Image</span>
                            <Image width={200} src={`${process.env.REACT_APP_API_URL}file/${booking.startImage}`} alt="start" />
                        </div>
                    )}
                    {booking.endImage && (
                        <div className="flex flex-row justify-between items-center my-3">
                            <span className="text-sm font-medium text-gray-700 dark:text-white">End Image</span>
                            <Image width={200} src={`${process.env.REACT_APP_API_URL}file/${booking.endImage}`} alt="end" />
                        </div>
                    )}
                </div>
            </Modal>
        </>
    );
};
const CustomModal = ({ title, open, onCancel, onOk, children }) => {
    const [modal, setModal] = React.useState(false);
    if (!open) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-transparent bg-opacity-1000" onClick={onCancel}>
            <div
                className="w-[600px] p-4 bg-zinc-900 rounded-lg shadow-xl text-white"
                onClick={(e) => e.stopPropagation()} // Prevent click from propagating to the backdrop
            >
                <h2 className="mb-4 text-lg font-semibold text-center text-slate-300">{title}</h2>
                <div className="mb-4">{children}</div>
                <div className="flex justify-end space-x-4">
                    <button onClick={onCancel} className="px-4 py-2 text-sm font-semibold text-gray-200 bg-gray-700 rounded-lg hover:bg-gray-600">
                        Cancel
                    </button>
                    <button onClick={onOk} className="px-4 py-2 text-sm font-semibold text-white bg-blue-600 rounded-lg hover:bg-blue-500">
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

const RemarksButton = ({ booking }) => {
    const [modal, setModal] = useState(false);
    const [remarks, setRemarks] = useState(booking.remarks || '');

    const handleSaveRemarks = () => {
        axios
            .post('/add-remarks', { remarks, bookingId: booking._id })
            .then((res) => {
                message.success('Remarks updated successfully');
                setModal(false);
            })
            .catch((err) => {
                message.error(err?.response?.data?.message || 'Failed to update remarks');
            });
    };

    return (
        <>
            {booking.status === 'completed' ? (
                <>
                    {booking.remarks ? (
                        <button onClick={() => setModal(true)} className="p-2 bg-gray-200 rounded-lg text-gray-700 hover:bg-gray-700 hover:text-zinc-50">
                            View Remarks
                        </button>
                    ) : (
                        <button onClick={() => setModal(true)} className="p-2 bg-gray-200 rounded-lg text-gray-700 hover:bg-gray-700 hover:text-zinc-50">
                            Add Remarks
                        </button>
                    )}

                    <CustomModal title={booking.remarks ? 'View / Edit Remarks' : 'Add Remarks'} open={modal} onCancel={() => setModal(false)} onOk={handleSaveRemarks}>
                        <textarea
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                            placeholder="Enter your remarks here"
                            rows={5}
                            className="w-full p-4 text-slate-700 rounded-lg bg-gray-300"
                        />
                    </CustomModal>
                </>
            ) : (
                <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-slate-400">Booking is not completed yet</span>
            )}
        </>
    );
};

const StartRideComponent = ({ booking, setBookings }) => {
    const [modal, setModal] = React.useState(false);
    const [image, setImage] = React.useState(null);
    const [startKilometers, setStartKilometers] = React.useState('');
    const [startFuel, setStartFuel] = React.useState('');

    const [disabled, setDisabled] = React.useState(true);

    const payments = booking.payments;
    console.log(payments);
    // filter paymentType = "balance"
    const balancePayment = payments.filter((payment) => payment.paymentType === 'balance')[0];

    const submitFn = (e) => {
        e.preventDefault();
        if (startKilometers == '') {
            message.error('Start Kilometers is required');
            return;
        }
        if (startFuel == '') {
            message.error('Start Fuel is required');
            return;
        }
        if (image == null) {
            message.error('Image is required');
            return;
        }
        const formData = new FormData();
        formData.append('bookingId', booking._id);
        formData.append('status', 'ongoing');
        formData.append('vehicleId', booking.vehicle._id);
        formData.append('startKm', startKilometers);
        formData.append('startFuel', startFuel);
        formData.append('image', image);
        // /start-ride
        axios
            .post('/start-ride', formData)
            .then((res) => {
                message.success('Booking Started');
                booking.status = 'ongoing';
                setBookings();
                setModal(false);
            })
            .catch((err) => {
                message.error(err?.respose?.data);
            });
    };
    return (
        <>
            <button
                disabled={booking.status != 'assigned'}
                onClick={() => {
                    setModal(true);
                }}
                className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white disabled:bg-gray-500 transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
            >
                <span>Start Ride</span>
            </button>
            <Modal title={null} footer={null} onCancel={() => setModal(false)} open={modal}>
                <div>
                    <form onSubmit={submitFn}>
                        <div className="flex flex-col">
                            <label className="text-sm font-medium text-gray-700 dark:text-gray-200">Start Kilometers</label>
                            <input
                                type="number"
                                value={startKilometers}
                                onChange={(e) => setStartKilometers(e.target.value)}
                                className="px-4 py-2 mt-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300"
                            />
                        </div>
                        <div className="flex flex-col mt-4">
                            <label className="text-sm font-medium text-gray-700 dark:text-gray-200">Start Fuel</label>
                            <input
                                type="number"
                                value={startFuel}
                                onChange={(e) => setStartFuel(e.target.value)}
                                className="px-4 py-2 mt-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300"
                            />
                        </div>
                        <div className="flex flex-col mt-4">
                            <label className="text-sm font-medium text-gray-700 dark:text-gray-200">Image</label>
                            <input
                                type="file"
                                onChange={(e) => setImage(e.target.files[0])}
                                className="px-4 py-2 mt-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300"
                            />
                        </div>
                        <Checkbox className="mt-4" checked={!disabled} onChange={(e) => setDisabled(!disabled)}>
                            <span className="text-xl font-medium text-gray-700 dark:text-gray-200">
                                <strong className="mx-1">{disabled ? 'Collect' : 'I have Collected'}</strong>
                                Balance Payment
                                <span className="ml-2">₹ {balancePayment?.amount || 0}</span>
                            </span>
                        </Checkbox>
                        <div className="flex flex-row justify-center mt-4">
                            <button
                                disabled={disabled}
                                className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 disabled:bg-slate-300 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
                            >
                                <span>Start Ride</span>
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
};

const ChangeEndDateComponent = ({ booking, setBookings }) => {
    const [modal, setModal] = React.useState(false);
    const [endDate, setEndDate] = React.useState(booking.endDate);
    const [loading, setLoading] = React.useState(false);
    console.log(typeof booking.endDate);
    const submitFn = (e) => {
        e.preventDefault();
        setLoading(true);
        axios
            .post('/admin/change-end-date', {
                bookingId: booking._id,
                endDate: dayjs(endDate).toISOString(),
            })
            .then((res) => {
                message.success('End Date Changed');
                booking.endDate = endDate;
                setBookings();
                setModal(false);
            })
            .catch((err) => {
                message.error(err?.respose?.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <>
            <button
                onClick={() => {
                    setModal(true);
                }}
                className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
            >
                <span>Change End Date</span>
            </button>
            <Modal title={null} footer={null} onCancel={() => setModal(false)} open={modal}>
                <div>
                    <form onSubmit={submitFn}>
                        <div className="flex flex-col">
                            <label className="text-sm font-medium text-gray-700 dark:text-gray-200">End Date</label>
                            <DatePicker
                                showTime
                                value={dayjs(endDate)}
                                variant="outlined"
                                onChange={(date, dateString) => {
                                    console.log(dateString, date);
                                    if (date == null) {
                                    } else {
                                        setEndDate(dateString);
                                    }
                                }}
                                className="px-4 py-2 mt-2 text-sm text-gray-700 border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none hover:bg-transparent dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300"
                            />
                        </div>
                        <div className="flex flex-row justify-center mt-4">
                            <button
                                disabled={loading}
                                className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
                            >
                                <span>Change End Date</span>
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
};

const EndRideComponent = ({ booking, setBookings }) => {
    const [modal, setModal] = React.useState(false);
    const [image, setImage] = React.useState(null);
    const [endKilometers, setEndKilometers] = React.useState('');
    const [endFuel, setEndFuel] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    console.log(booking);
    const [resp, setResp] = React.useState({
        finalPayment: 0,
        calculation: [],
    });

    const [screen, setScreen] = React.useState(1);
    const submitFn = (e) => {
        e.preventDefault();
        setLoading(true);
        if (endKilometers == '') {
            message.error('End Kilometers is required');
            return;
        }
        if (endFuel == '') {
            message.error('End Fuel is required');
            return;
        }
        if (image == null) {
            message.error('Image is required');
            return;
        }
        const formData = new FormData();
        formData.append('bookingId', booking._id);
        formData.append('status', 'ongoing');
        formData.append('vehicleId', booking.vehicle._id);
        formData.append('endKm', endKilometers);
        formData.append('endFuel', endFuel);
        formData.append('image', image);
        // /start-ride
        axios
            .post('/end-ride', formData)
            .then((res) => {
                // message.success("Ride Ended");
                // booking.status = "completed";
                // setBookings();
                setResp(res.data.data);
                setScreen(2);
                setLoading(false);
            })
            .catch((err) => {
                message.error(err?.respose?.data);
            });
    };

    return (
        <>
            <button
                disabled={booking.status != 'ongoing' || booking.status == 'completed' || booking.status == 'failed'}
                onClick={() => {
                    setModal(true);
                }}
                className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white disabled:bg-gray-500 transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-blue"
            >
                <span>Complete Ride</span>
            </button>
            <Modal title={null} footer={null} onCancel={() => setModal(false)} open={modal}>
                {screen == 1 && (
                    <div>
                        <form onSubmit={submitFn}>
                            <div className="flex flex-col">
                                <label className="text-sm font-medium text-gray-700 dark:text-gray-200">End Kilometers</label>
                                <input
                                    type="number"
                                    value={endKilometers}
                                    onChange={(e) => setEndKilometers(e.target.value)}
                                    className="px-4 py-2 mt-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300"
                                />
                            </div>
                            <div className="flex flex-col mt-4">
                                <label className="text-sm font-medium text-gray-700 dark:text-gray-200">End Fuel</label>
                                <input
                                    type="number"
                                    value={endFuel}
                                    onChange={(e) => setEndFuel(e.target.value)}
                                    className="px-4 py-2 mt-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300"
                                />
                            </div>
                            <div className="flex flex-col mt-4">
                                <label className="text-sm font-medium text-gray-700 dark:text-gray-200">Image</label>
                                <input
                                    type="file"
                                    onChange={(e) => setImage(e.target.files[0])}
                                    className="px-4 py-2 mt-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300"
                                />
                            </div>
                            <div className="flex flex-row justify-center mt-4">
                                <button
                                    disabled={loading}
                                    className="flex items-center disabled:bg-slate-300 justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
                                >
                                    {loading && (
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
                                        </svg>
                                    )}
                                    <span>End Ride</span>
                                </button>
                            </div>
                        </form>
                    </div>
                )}
                {screen == 2 && (
                    <div>
                        <div className="flex flex-col">
                            <h2 className="text-2xl font-bold">Booking Completed</h2>
                            {resp.calculation.map((item, index) => (
                                <h2 className="text-base font-normal">{item}</h2>
                            ))}
                            <h2 className="text-lg font-semibold">Pending amount to be paid ₹ {resp.finalPayment}</h2>
                        </div>
                        <Checkbox onChange={() => setDisabled(!disabled)} checked={disabled}>
                            <p>I have collected the amount</p>
                        </Checkbox>
                        <div className="flex flex-row justify-center mt-4">
                            <button
                                disabled={!disabled}
                                onClick={() => {
                                    message.success('Ride Ended');
                                    booking.status = 'completed';
                                    setBookings();
                                    setModal(false);
                                }}
                                className="flex items-center justify-between disabled:bg-slate-300 px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
                            >
                                <span>Finish</span>
                            </button>
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
};

const BookingRow = ({ booking, setBookings }) => {
    const [bikeAssigned, setBikeAssigned] = React.useState(booking.vehicle);

    const [availableVehicles, setAvailableVehicles] = React.useState([]);

    const [loading, setLoading] = React.useState(false);
    const { user } = useAuth();
    useEffect(() => {
        setLoading(true);
        axios
            .get('/vehicles-status', {
                params: {
                    bookingId: booking._id,
                    startDate: booking.startDate,
                    endDate: booking.endDate,
                },
            })
            .then((res) => {
                var availableVehicles = res.data;
                console.log(booking.vehicle._id, availableVehicles[0]._id);

                setBikeAssigned(booking.vehicle);
                setAvailableVehicles(availableVehicles);
                setLoading(false);
            });
    }, [booking]);

    return (
        <tr className="border-b border-gray-200 dark:border-gray-700 dark:bg-slate-900 hover:bg-slate-800 dark:hover:bg-slate-800">
            <td className="px-4 py-3">
                <div className="flex items-center text-sm">
                    <div>
                        <p className="font-semibold text-xs text-gray-700 dark:text-white">#{booking._id}</p>
                        <p className="font-semibold text-gray-700 dark:text-white">{booking.user.displayName}</p>
                        {/* <p className="text-xs text-gray-600 dark:text-white">+91 9999999999</p> */}
                    </div>
                </div>
            </td>
            <td>
                {
                    // call booking.mobile button with ring and blue color
                    <a href={`tel:${booking?.mobile}`} className="text-neutral-200 ">
                        <p className="font-semibold text-gray-700 dark:text-white">{booking?.mobile}</p>
                    </a>
                }
            </td>
            <td className="px-4 py-3 text-sm">
                <p className="font-semibold text-gray-700 dark:text-white">{booking.vehicle.name}</p>
                <p className="text-xs text-gray-600 dark:text-white">{booking.vehicle.rcNumber}</p>
            </td>
            <td className="px-4 py-3 text-sm">
                <p className="font-semibold text-gray-700 dark:text-white">{booking.vehicle.location}</p>
            </td>
            <td className="px-4 py-3 text-sm">
                <p className="font-semibold text-gray-700 dark:text-white">{booking.outStation ? 'YES' : 'NO'}</p>
            </td>
            <td className="px-4 py-3 text-sm">
                <p className="font-semibold text-gray-700 dark:text-white">
                    {`Start `}
                    {new Date(booking.startDate).toLocaleString('en-IN', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                    })}
                </p>
                <p className="font-semibold text-gray-700 dark:text-white">
                    {`End `}
                    {new Date(booking.endDate).toLocaleString('en-IN', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                    })}
                </p>
            </td>
            <td className="px-4 py-3 text-sm">
                <p className="font-semibold text-gray-700 dark:text-white">{`₹ ${booking.reserveAmount} /-`}</p>
                <p className="font-semibold capitalize text-gray-700 dark:text-white">{booking.transaction.status}</p>
            </td>
            <td className="px-4 py-3 text-sm">
                <p className="font-semibold text-gray-700 dark:text-white">
                    {new Date(booking.createdAt).toLocaleString('en-IN', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                    })}
                </p>
            </td>
            <td className="px-4 py-3 text-sm">
                {
                    // for booked bookings
                    /* <span className="px-2 py-1 text-xs font-semibold text-green-900 uppercase bg-green-200 rounded-full">Booked</span> */

                    // for failed bookings
                    /* <span className="px-2 py-1 text-xs font-semibold text-red-900 uppercase bg-red-200 rounded-full">Failed</span> */

                    // for completed bookings
                    /* <span className="px-2 py-1 text-xs font-semibold text-blue-900 uppercase bg-blue-200 rounded-full">Completed</span> */

                    // for cancelled bookings
                    /* <span className="px-2 py-1 text-xs font-semibold text-yellow-900 uppercase bg-yellow-200 rounded-full">Cancelled</span> */

                    // for pending bookings
                    /* <span className="px-2 py-1 text-xs font-semibold text-gray-900 uppercase bg-gray-200 rounded-full">Pending</span> */
                    <>
                        {booking.status === 'pending' && (
                            <span className="px-2 py-1 text-xs font-semibold text-yellow-900 uppercase bg-yellow-200 rounded-full">Pending</span>
                        )}
                        {booking.status === 'booked' && (
                            <span className="px-2 py-1 text-xs font-semibold text-green-900 uppercase bg-green-200 rounded-full">Booked</span>
                        )}
                        {booking.status === 'failed' && <span className="px-2 py-1 text-xs font-semibold text-red-900 uppercase bg-red-200 rounded-full">Failed</span>}
                        {booking.status === 'completed' && (
                            <span className="px-2 py-1 text-xs font-semibold text-blue-900 uppercase bg-blue-200 rounded-full">Completed</span>
                        )}
                        {booking.status === 'assigned' && (
                            <span className="px-2 py-1 text-xs font-semibold text-blue-900 uppercase bg-blue-200 rounded-full">Assigned</span>
                        )}
                        {booking.status === 'cancelled' && (
                            <span className="px-2 py-1 text-xs font-semibold text-yellow-900 uppercase bg-yellow-200 rounded-full">Cancelled</span>
                        )}
                        {booking.status === 'ongoing' && (
                            <span className="px-2 py-1 text-xs font-semibold text-blue-900 uppercase bg-blue-200 rounded-full">Ongoing</span>
                        )}
                    </>
                }
            </td>
            <td className="px-4 py-3 text-sm">
                <AddtionalDetailsButton booking={booking} />
            </td>
            <td className="px-4 py-3 text-sm">
                <div className="flex items-center space-x-4 text-sm">
                    {
                        // dropdown assign button
                        <Popover
                            showArrow={false}
                            overlayInnerStyle={{ padding: 0 }}
                            content={
                                booking.status != 'booked' && booking.status != 'assigned' ? null : (
                                    <div className="dark:bg-slate-700" style={{ width: 300, padding: 10 }}>
                                        <div style={{ height: 200 }} className="flex flex-col overflow-hidden ">
                                            {
                                                // bikes list
                                                <div className="flex flex-col overflow-y-auto">
                                                    {availableVehicles.map((vehicle, index) => (
                                                        <div
                                                            key={index}
                                                            onClick={() => {
                                                                // booking.vehicle = vehicle;
                                                                if (vehicle.booked && vehicle._id != booking.vehicle._id) {
                                                                    message.error('Vehicle is already booked');
                                                                    return;
                                                                }
                                                                setBikeAssigned(vehicle);
                                                            }}
                                                            className={
                                                                'flex flex-row items-left py-1 my-1 text-sm px-2 rounded-md font-medium leading-5 text-white ' +
                                                                (bikeAssigned._id == vehicle?._id
                                                                    ? booking.vehicle._id == bikeAssigned._id
                                                                        ? 'bg-green-800'
                                                                        : 'bg-blue-900'
                                                                    : vehicle.booked && vehicle._id != booking.vehicle._id
                                                                    ? 'bg-red-900'
                                                                    : 'bg-slate-800')
                                                            }
                                                        >
                                                            <div className="flex justify-center items-center">
                                                                {
                                                                    // vehicle._id === bikeAssigned._id green tick
                                                                    bikeAssigned._id == vehicle?._id && (
                                                                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                                                        </svg>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className="flex flex-col">
                                                                <span className="text-lg">{vehicle.name}</span>
                                                                <span className="text-sm">{vehicle.rcNumber}</span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                        <div className="flex flex-row justify-center mt-3">
                                            <button
                                                disabled={loading}
                                                onClick={() => {
                                                    axios
                                                        .post('change-booking-status', {
                                                            bookingId: booking._id,
                                                            status: 'assigned',
                                                            vehicleId: bikeAssigned._id,
                                                        })
                                                        .then((res) => {
                                                            message.success('Booking Assigned');
                                                            booking.vehicle = bikeAssigned;
                                                            setBikeAssigned(bikeAssigned);
                                                            setBookings();
                                                        })
                                                        .catch((err) => {
                                                            message.error(err?.respose?.data);
                                                        });
                                                }}
                                                className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-md active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
                                            >
                                                {loading && (
                                                    <svg
                                                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
                                                    </svg>
                                                )}
                                                <span>Assign</span>
                                            </button>
                                        </div>
                                    </div>
                                )
                            }
                        >
                            <div>
                                <button
                                    disabled={booking.status != 'booked' && booking.status != 'assigned'}
                                    className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 disabled:bg-gray-500 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-700 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
                                >
                                    Assign / Re-Assign
                                </button>
                            </div>
                        </Popover>
                    }
                    {booking.status === 'assigned' && (
                        <>
                            <StartRideComponent booking={booking} setBookings={setBookings} />
                        </>
                    )}
                    {booking.status === 'ongoing' && (
                        <>
                            {user.user.role == 'superadmin' && <ChangeEndDateComponent booking={booking} setBookings={setBookings} />}
                            <EndRideComponent booking={booking} setBookings={setBookings} />
                        </>
                        // <button
                        //     onClick={() => {
                        //         axios.post("change-booking-status", {
                        //             bookingId: booking._id,
                        //             status: "completed",
                        //             vehicleId: booking.vehicle._id
                        //         }).then(res => {
                        //             message.success("Booking Completed");
                        //             booking.status = "completed";
                        //             setBookings();
                        //         }).catch(err => {
                        //             message.error(err?.respose?.data);
                        //         })
                        //     }}
                        //     disabled={(booking.status != "ongoing" || booking.status == "completed" || booking.status == "failed")}
                        //     className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white disabled:bg-gray-500 transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-blue">
                        //     <span>Complete Ride</span>
                        // </button>
                    )}
                    <button
                        onClick={() => {
                            axios
                                .post('change-booking-status', {
                                    bookingId: booking._id,
                                    status: 'cancelled',
                                    vehicleId: booking.vehicle._id,
                                })
                                .then((res) => {
                                    message.success('Booking Cancelled');
                                    booking.status = 'ongoing';
                                    setBookings();
                                })
                                .catch((err) => {
                                    message.error(err?.respose?.data);
                                });
                        }}
                        disabled={booking.status == 'cancelled' || booking.status == 'ongoing' || booking.status == 'completed' || booking.status == 'failed'}
                        className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white disabled:bg-gray-500 transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-blue"
                    >
                        <span>Cancel</span>
                    </button>
                </div>
            </td>
            <td className="px-5 py-5 text-sm">
                <RemarksButton booking={booking} />
            </td>
        </tr>
    );
};

const Bookings = () => {
    const [bookings, setBookings] = React.useState([]);
    const [checkedStatuses, setCheckedStatuses] = React.useState({
        pending: false,
        booked: true,
        ongoing: true,
        assigned: true,
        completed: false,
        cancelled: false,
        failed: false,
    });
    const [bookingDates, setBookingDates] = React.useState({
        startDate: dayjs().subtract(1, 'day'),
        endDate: dayjs().add(2, 'day'),
    });
    const [bookingLocation, setBookingLocation] = React.useState('All');
    const [bookingLocations, setBookingLocations] = React.useState([]);
    // const [skip, setSkip] = React.useState(0);
    // const [limit, setLimit] = React.useState(10);
    const retrieveBookings = () => {
        axios
            .get('/bookings', {
                params: {
                    startDate: bookingDates.startDate.toDate(),
                    endDate: bookingDates.endDate.toDate(),
                    status: Object.keys(checkedStatuses).filter((key) => checkedStatuses[key]),
                    location: bookingLocation,
                },
            })
            .then((res) => {
                // console.log(res.data);
                setBookings(res.data.data);
            });
    };
    const retrieveBookingLocations = () => {
        axios.get('/available-locations').then((res) => {
            // console.log(res.data);
            setBookingLocations(res.data.data);
        });
    };

    useEffect(() => {
        retrieveBookings();
    }, [checkedStatuses, bookingDates, bookingLocation]);

    useEffect(() => {
        retrieveBookingLocations();
    }, []);

    return (
        <div>
            <div className="flex flex-wrap -mx-3">
                <div className="w-full max-w-full px-3 shrink-0 md:w-12/12 md:flex-0">
                    {
                        // Bookings table
                        <div>
                            <div className="flex flex-col w-full h-full p-6 mb-4 bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                                <div className="flex flex-row justify-between">
                                    <div className="flex flex-col">
                                        <span className="text-sm font-medium text-gray-500 dark:text-white">Bookings</span>
                                        <span className="text-xl font-semibold text-gray-700 dark:text-white">All Bookings</span>
                                    </div>
                                    <div className="flex flex-col">
                                        <span className="text-sm font-medium text-gray-500 dark:text-white">Total</span>
                                        <span className="text-xl font-semibold text-gray-700 dark:text-white">0</span>
                                    </div>
                                </div>
                                <div className="flex flex-col mt-4">
                                    <div className="flex flex-row justify-between">
                                        <div className="flex flex-col">
                                            <span className="text-sm font-medium text-gray-500 dark:text-white">Active</span>
                                            <span className="text-xl font-semibold text-gray-700 dark:text-white">0</span>
                                        </div>
                                        <div className="flex flex-col">
                                            <span className="text-sm font-medium text-gray-500 dark:text-white">Completed</span>
                                            <span className="text-xl font-semibold text-gray-700 dark:text-white">0</span>
                                        </div>
                                        <div className="flex flex-col">
                                            <span className="text-sm font-medium text-gray-500 dark:text-white">Cancelled</span>
                                            <span className="text-xl font-semibold text-gray-700 dark:text-white">0</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                // section to display "booked" "failed" "cancelled" "ongoing" "completed" checkable buttons
                                <div className="flex flex-row flex-wrap justify-around my-3 bg-slate-850 p-3 rounded-lg">
                                    <div className="flex flex-row space-x-3 overflow-auto">
                                        <button
                                            onClick={() => {
                                                setCheckedStatuses({
                                                    ...checkedStatuses,
                                                    pending: !checkedStatuses.pending,
                                                });
                                            }}
                                            className={
                                                (checkedStatuses.pending ? 'bg-green-500 ' : 'bg-blue-900 ') +
                                                'flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 border border-transparent rounded-md focus:outline-none focus:shadow-outline-blue'
                                            }
                                        >
                                            <span>Pending</span>
                                        </button>
                                        <button
                                            onClick={() => {
                                                setCheckedStatuses({
                                                    ...checkedStatuses,
                                                    booked: !checkedStatuses.booked,
                                                });
                                            }}
                                            className={
                                                (checkedStatuses.booked ? 'bg-green-500 ' : 'bg-blue-900 ') +
                                                'flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 border border-transparent rounded-md focus:outline-none focus:shadow-outline-blue'
                                            }
                                        >
                                            <span>Booked</span>
                                        </button>
                                        <button
                                            onClick={() => {
                                                setCheckedStatuses({
                                                    ...checkedStatuses,
                                                    failed: !checkedStatuses.failed,
                                                });
                                            }}
                                            className={
                                                (checkedStatuses.failed ? 'bg-green-500 ' : 'bg-blue-900 ') +
                                                'flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 border border-transparent rounded-md focus:outline-none focus:shadow-outline-blue'
                                            }
                                        >
                                            <span>Failed</span>
                                        </button>
                                        <button
                                            onClick={() => {
                                                setCheckedStatuses({
                                                    ...checkedStatuses,
                                                    cancelled: !checkedStatuses.cancelled,
                                                });
                                            }}
                                            className={
                                                (checkedStatuses.cancelled ? 'bg-green-500 ' : 'bg-blue-900 ') +
                                                'flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 border border-transparent rounded-md focus:outline-none focus:shadow-outline-blue'
                                            }
                                        >
                                            <span>Cancelled</span>
                                        </button>
                                        <button
                                            onClick={() => {
                                                setCheckedStatuses({
                                                    ...checkedStatuses,
                                                    ongoing: !checkedStatuses.ongoing,
                                                });
                                            }}
                                            className={
                                                (checkedStatuses.ongoing ? 'bg-green-500 ' : 'bg-blue-900 ') +
                                                'flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 border border-transparent rounded-md focus:outline-none focus:shadow-outline-blue'
                                            }
                                        >
                                            <span>Ongoing</span>
                                        </button>
                                        <button
                                            onClick={() => {
                                                setCheckedStatuses({
                                                    ...checkedStatuses,
                                                    assigned: !checkedStatuses.assigned,
                                                });
                                            }}
                                            className={
                                                (checkedStatuses.assigned ? 'bg-green-500 ' : 'bg-blue-900 ') +
                                                'flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 border border-transparent rounded-md focus:outline-none focus:shadow-outline-blue'
                                            }
                                        >
                                            <span>Assigned</span>
                                        </button>
                                        <button
                                            onClick={() => {
                                                setCheckedStatuses({
                                                    ...checkedStatuses,
                                                    completed: !checkedStatuses.completed,
                                                });
                                            }}
                                            className={
                                                (checkedStatuses.completed ? 'bg-green-500 ' : 'bg-blue-900 ') +
                                                'flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 border border-transparent rounded-md focus:outline-none focus:shadow-outline-blue'
                                            }
                                        >
                                            <span>Completed</span>
                                        </button>
                                    </div>
                                    <div className="my-3">
                                        {
                                            // date range selector
                                            <DatePicker.RangePicker
                                                value={[bookingDates.startDate, bookingDates.endDate]}
                                                className="bg-transparent text-white force-white"
                                                onChange={([startDate, endDate]) => {
                                                    setBookingDates({
                                                        startDate: startDate,
                                                        endDate: endDate,
                                                    });
                                                }}
                                                showTime
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                suffixIcon={null}
                                                allowClear={false}
                                                direction="horizontal"
                                            />
                                        }
                                    </div>
                                    <div className="my-3">
                                        {
                                            // select with options to select "all", ...bookingLocations
                                            <select
                                                value={bookingLocation}
                                                onChange={(e) => {
                                                    setBookingLocation(e.target.value);
                                                }}
                                                className="text-white bg-transparent"
                                            >
                                                <option value="All">All</option>
                                                {bookingLocations.map((location, index) => (
                                                    <option key={index} value={location}>
                                                        {location}
                                                    </option>
                                                ))}
                                            </select>
                                        }
                                    </div>
                                </div>
                            }

                            <div className="relative overflow-hidden flex p-3 flex-col min-w-0 mb-6 break-words border-0 border-transparent dark:bg-slate-850 dark:shadow-dark-xl  border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                                {/* <div className="flex flex-wrap ">
                                    {bookings.map((booking) => {
                                        return <BookingCard booking={booking} />;
                                    })}
                                </div> */}
                                <div className="overflow-x-auto">
                                    <table className="w-full whitespace-nowrap  overflow-x-auto">
                                        <thead>
                                            <tr className="text-xs font-semibold tracking-wide text-left text-white dark:bg-slate-900 uppercase border-b dark:border-gray-700 dark:text-gray-400 ">
                                                <th className="px-4 py-3">Booking ID</th>
                                                <th className="px-4 py-3">Mobile No</th>
                                                <th className="px-4 py-3">Current Assigned Bike Details</th>
                                                <th className="px-4 py-3">Location</th>
                                                <th className="px-4 py-3">OUT STATION</th>
                                                <th className="px-4 py-3">Date</th>
                                                <th className="px-4 py-3">Payment</th>
                                                <th className="px-4 py-3">Booked On</th>
                                                <th className="px-4 py-3">Status</th>
                                                <th className="px-4 py-3">Additonal Details</th>
                                                <th className="px-4 py-3">Actions</th>
                                                <th className="px-4 py-3">Remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y dark:divide-slate-700 ">
                                            {bookings.map((booking) => {
                                                return <BookingRow booking={booking} setBookings={retrieveBookings} />;
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Bookings;
