import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';
import './assets/styles/argon-dashboard-tailwind.css';
import './assets/styles/nucleo-icons.css';
import './assets/styles/nucleo-svg.css';
import './assets/styles/tooltips.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import axios from './services/api';
import { ConfigProvider, theme } from 'antd';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <ConfigProvider
        theme={{
            algorithm: theme.darkAlgorithm,
            token: {
            },
        }}
    >
        <AuthProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </AuthProvider>
    </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
