import React, { useEffect, useState } from "react";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Modal, Switch, Pagination } from "antd";
import axios from "../../services/api";

const UserModal = ({ user }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <>
      <button
        type="button"
        className="flex justify-center mx-2 px-2 py-2 text-xl font-medium text-white transition duration-150 ease-in-out bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
        onClick={() => setIsModalVisible(true)}
      >
        <EyeOutlined />
      </button>
      <Modal
        title="User Details"
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={800}
      >
        <div className="space-y-4">
          {Object.entries(user).map(([key, value]) =>
            key !== "photoURL" ? (
              <div key={key} className="flex flex-row border-b pb-2">
                <div className="w-1/2 font-medium capitalize">
                  {key.replace(/([A-Z])/g, " $1")}
                </div>
                <div className="w-1/2">{value}</div>
              </div>
            ) : null
          )}
        </div>
      </Modal>
    </>
  );
};

const ListUsers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);
  const fetchUsers = () => {
    axios.get("/users").then((response) => {
      const apiUsers = response.data.data.map((user) => ({
        id: user.uid,
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        status: user.status,
        blocked: user.status === "blocked" ? true : false,
      }));
      setUsers(apiUsers);
    });
  };
  const handleBlockUser = (userId, blocked) => {
    console.log(userId, blocked);
    if (blocked) {
      axios.get(`/user/blacklist/${userId}`).then((response) => {
        console.log(response);
      });
      fetchUsers();
    } else {
      axios.get(`/user/whitelist/${userId}`).then((response) => {
        console.log(response);
      });
      fetchUsers();
    }
  };

  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * pageSize;
    return users.slice(startIndex, startIndex + pageSize);
  };

  return (
    <div className="bg-gray-900 rounded-lg shadow-lg">
      <div className="h-[500px] overflow-hidden flex flex-col">
        <div className="flex-grow overflow-x-auto rounded-t-2xl">
          <table className="min-w-full divide-y divide-gray-700">
            <thead className="bg-gray-800">
              <tr>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">
                  Name
                </th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">
                  Email
                </th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">
                  Status
                </th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">
                  Block User
                </th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-300">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700 bg-gray-900">
              {getCurrentPageData().map((user) => (
                <tr
                  key={user.id}
                  className="transition-colors duration-200 hover:bg-gray-800/50"
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <img
                        className="h-10 w-10 rounded-full object-cover"
                        src={user.photoURL}
                        alt=""
                      />
                      <div className="ml-4 text-sm text-gray-200">
                        {user.displayName}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-200">
                    <a
                      href={`mailto:${user.email}`}
                      className="hover:text-blue-400"
                    >
                      {user.email}
                    </a>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        user.status === "active"
                          ? "bg-green-100 text-green-800"
                          : "bg-red-100 text-red-800"
                      }
                      ${
                        user.status === "blocked"
                          ? "bg-red-500 text-gray-800"
                          : ""
                      }
                      `}
                    >
                      {/* first letter of status is capitalized */}
                      {user.status.charAt(0).toUpperCase() +
                        user.status.slice(1)}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Switch
                      checked={user.blocked}
                      onChange={(checked) => handleBlockUser(user.id, checked)}
                      className="border-2 border-gray-600 rounded-full"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-xl text-gray-400 flex">
                    <button className="flex justify-center mx-2 px-2 py-2 text-xl text-white rounded-md hover:bg-gray-700 transition-colors duration-200">
                      <EditOutlined />
                    </button>
                    <UserModal user={user} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="bg-gray-800 p-4 rounded-b-2xl">
          <Pagination
            current={currentPage}
            onChange={setCurrentPage}
            total={users.length}
            pageSize={pageSize}
            className="flex justify-end"
          />
        </div>
      </div>
    </div>
  );
};

export default ListUsers;
